export const SeriesRulesValidation = (rule, value) => {
  if (!Array.isArray(value)) return false;

  return (
    value.some(row => {
      const name = (row.series_name || "").trim();
      const rawCondition = row.series_query_condition;

      const isQueryConditionValid =
        typeof rawCondition === "object" &&
        rawCondition.query_condition &&
        Array.isArray(rawCondition.query_condition.children) &&
        rawCondition.query_condition.children.length > 0;

      const isCompletelyEmpty = !name && (!rawCondition || rawCondition === "");

      // Skip empty rows
      if (isCompletelyEmpty) return false;

      // Row has some input but not valid → fail
      return !name || !isQueryConditionValid;
    }) === false
  );
};
